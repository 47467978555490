<template>
  <div class="login-container">
    <div class="login_header">
      <img @click="gotoindex()" src="/imgs/tt-logo.png" alt="" />
    </div>
    <div class="login_content">
      <div class="container">
        <div class="text-content">
          <div class="title">
            天天数链
          </div>
          <div class="describe">
            开源企业、通用算法、分布式算力
          </div>
        </div>
        <div class="wx-card">
          <div class="wx-login">
            <span class="header-title">微信扫码登录/注册</span>
          </div>

          <div class="phone-login">
            <img
                src="/imgs/to-phone-login.png"
                class="phone-img"
                @click="toPhoneLogin"
                alt=""
            >
          </div>

          <div class="text-center" style="margin-top: 64px">
            <img
                class="wx-img"
                :src="qrcodeUrl || null"
                alt=""
            />
          </div>

          <div class="text-center" style="margin-top: 12px">
            <span class="tip-login">扫码关注公众号完成登录</span>
          </div>

          <!-- <div class="text-center" style="margin-top: 32px">
            <span class="agree">登录即同意</span>
            <span class="sign-agreement" @click="toLegalNotices">《法律声明及隐私权限》</span>
            <span class="agree">和</span>
            <span class="sign-agreement" @click="toUserAgreement">《用户协议》</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="login_footer">
      <div class="login_footer_bottom">
        <p>Copyright ©版权所有 2021- 2022湖北天天数链技术有限公司</p>
        <p class="copyright"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="openWA" style="margin-right: 20px;">鄂ICP备2020018985号-1</a><span data-v-a3e0e448=""><img data-v-a3e0e448="" src="https://www.tntlinking.com/static/img/bei.png" alt="网安"><a data-v-a3e0e448="" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004850" target="_blank" class="openWA">鄂公网安备 42018502004850号</a></span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { login,getWechatQrcode, getWechatToken } from '../api/login'
export default {
  name: "WXLogin",
  data() {
    return {
      qrcodeUrl: "",
      wechatQrcode: {
        clientKey: process.env.VUE_APP_CLIENTKEY,
        // clientKey: 'ttslManpowerProd',
        channel: "Member",
      },
      wechatTicketForm: {
        ticket: "",
      },
      weChatAccessTokenTimer: "",
      // loginForm: {
      //   grant_type: "sms_code",
      //   scope: "all",
      //   smsCode: "",
      //   mobile: "",
      //   loginChannel: "Recruiter_PC_Web",
      //   wechatId: "",
      // },
    }
  },
  mounted() {
    this.getWechatQrcode();
  },
  methods: {
    // 跳转到首页
    gotoindex() {
      this.$router.push("/product");
    },
    // 跳转手机号登录
    toPhoneLogin() {
      this.cancelWeChatTimer()
      this.$router.push({name: 'login'})
    },

    // 获取场景二维码
    getWechatQrcode() {
      getWechatQrcode(this.wechatQrcode).then((res) => {
        if (res.data.ticket) {
          this.qrcodeUrl = res.data.qrcodeUrl;
          this.wechatTicketForm.ticket = res.data.ticket;
          this.weChatTimer();
        }
      })
    },

    // 定时轮询获取微信号关注信息
    weChatTimer() {
      this.weChatAccessTokenTimer = window.setInterval(() => {
        setTimeout(this.queryWeChatAccessTokenFlag, 0);
      }, 1500);
    },

    // 取消定时轮询任务
    cancelWeChatTimer() {
      clearInterval(this.weChatAccessTokenTimer);
    },

    // 是否需要轮询
    queryWeChatAccessTokenFlag() {
      if (this.wechatTicketForm.ticket) {
        this.queryWeChatAccessToken()
      }
    },

    // 获取微信公众号的信息
    queryWeChatAccessToken () {
      if (localStorage.getItem("token")) {
        localStorage.setItem("token", "");
        localStorage.setItem("userInfo", "");
        this.$store.commit("setToken", "");
        this.$store.commit("setUserInfo", "");
      }
      getWechatToken(this.wechatTicketForm).then((res) => {
        if (res.data && res.data.unionid) {
          this.cancelWeChatTimer()
          if (res.data.mobile) {
            // this.loginForm.mobile = res.data.mobile;
            // this.loginForm.smsCode = res.data.access_token;
            // this.loginForm.wechatId = res.data.unionid;
            let params = {
              mobile: res.data.mobile,
              smsCode: res.data.access_token,
              grant_type: 'sms_code',
              scope: 'all',
              loginChannel: 'Member'
            }
            login(params)
              .then((res) => {
                if(res.code === 200) {
                  localStorage.setItem("token", res.data.access_token);
                  // getInfo().then(res => {
                  //   if(res.code === 200) {
                  //     localStorage.setItem("userInfo", JSON.stringify(res.data));
                  //   }
                  // } )
                  this.gotoindex()
                } else if (res.code === 500) {
                  this.$message.error(res.message);
                }
              })
          } else {
            this.$router.push({path: '/bind-phone', query: {wechatId: res.data.unionid}});
            this.$message.warning("登录成功，请绑定您的手机号");
          }
        }
      });
    }
  },
  beforeDestroy(){
    this.cancelWeChatTimer();
  },

}
</script>

<style lang="scss" scoped>
@import "../assets/scss/wx-login.scss";
</style>
